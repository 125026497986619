import React, { useState, useEffect } from 'react';
import TodoForm from './TodoForm';
import Todo from './Todo';
import Header from './Header';
import Dice from './Dice'; // Import the Dice component
import { FaSave } from 'react-icons/fa'; // Import FaSave icon
import ReactGA from 'react-ga4'; // Import ReactGA for Google Analytics



function TodoList() {
  const [todos, setTodos] = useState(() => {
    try {
      const savedTodos = localStorage.getItem('todos');
      return savedTodos ? JSON.parse(savedTodos) : [];
    } catch (error) {
      console.error('Failed to retrieve todos from localStorage:', error);
      return []; // Return an empty array if parsing fails
    }
  });
  const [animationOn, setAnimationOn] = useState(true);
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility
  

  
  const addTodo = todo => {
    if (!todo.text || /^\s*$/.test(todo.text)) {
      return;
    }

    const newTodos = [todo, ...todos];
    setTodos(newTodos);
  };

  const updateTodo = (todoId, newValue) => {
    if (!newValue.text || /^\s*$/.test(newValue.text)) {
      return;
    }

    setTodos(prev => prev.map(item => (item.id === todoId ? newValue : item)));
  };


  const removeTodo = id => {
    // Confirm before removing
    const confirmDelete = window.confirm("Are you sure you want to delete this task?");
    if (confirmDelete) {
      const updatedCurrentTodos = todos.filter(todo => todo.id !== id);
      setTodos(updatedCurrentTodos);
    }
  };

  const completeTodo = id => {
    let updatedTodos = todos.map(todo => {
      if (todo.id === id) {
        return { ...todo, isComplete: !todo.isComplete };
      }
      return todo;
    });

    updatedTodos.sort((a, b) => {
      if (a.isComplete && !b.isComplete) return 1;
      if (!a.isComplete && b.isComplete) return -1;
      return 0;
    });

    setTodos(updatedTodos);
  };

  const moveToTop = id => {
    const updatedTodos = todos.filter(todo => todo.id !== id);
    const todoToMove = todos.find(todo => todo.id === id);
    setTodos([todoToMove, ...updatedTodos]);
  };



  useEffect(() => {
    localStorage.setItem('todos', JSON.stringify(todos));
  }, [todos]); // This effect runs whenever the todos change

  useEffect(() => {
    const savedTodos = JSON.parse(localStorage.getItem('todos'));
    if (savedTodos) {
      setTodos(savedTodos);
    }
  }, []); // This effect runs only once when the component mounts

  const saveTodosAsList = () => {
    if (todos.length === 0) {
      alert("Cannot save an empty todo list.");
      return;
    }

    const currentSavedLists = JSON.parse(localStorage.getItem('savedTodoLists') || '[]');
    const newList = {
      timestamp: new Date().toISOString(),
      todos: [...todos]
    };

    localStorage.setItem('savedTodoLists', JSON.stringify([...currentSavedLists, newList]));

    // Show the popup and hide it after a delay
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 1000); // Hide the popup after 10 seconds
  };
  const handleSaveClick = () => {
    ReactGA.event({
      category: "Button",
      action: "Save List",
      label: "Save Button",
    });
  };
  
  const handleRandomTask = (selectedTask) => {
    const newTodos = todos.filter(todo => todo.id !== selectedTask.id);
    newTodos.unshift(selectedTask);
    setTodos(newTodos);
    alert(`Your random task is: ${selectedTask.text}`);
  };

  const [draggedTodoId, setDraggedTodoId] = useState(null);

  const onDragStart = (e, todoId) => {
    setDraggedTodoId(todoId);
    e.dataTransfer.setData('text/plain', todoId); // for Firefox compatibility
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, targetTodoId) => {
    const draggedTodoIndex = todos.findIndex(todo => todo.id === draggedTodoId);
    const targetTodoIndex = todos.findIndex(todo => todo.id === targetTodoId);

    const newTodos = [...todos];
    const [reorderedTodo] = newTodos.splice(draggedTodoIndex, 1);
    newTodos.splice(targetTodoIndex, 0, reorderedTodo);

    setTodos(newTodos);
    setDraggedTodoId(null);
    e.dataTransfer.clearData(); // for Firefox compatibility
  };
  

  return (
    <>
      <Header isAnimationOn={animationOn} toggleAnimation={() => setAnimationOn(!animationOn)} />
      


      <div className="todo-app">
        <h1>Mission: Task Possible!</h1>
        <TodoForm onSubmit={addTodo} />
        <div className="buttons-container">
          <Dice 
          todos={todos} 
          onRandomSelect={handleRandomTask}/>
          {showPopup && (
          <div className="popup">
            List saved!
          </div>
          )}

          <button 
            onClick={() => {
              saveTodosAsList();
              handleSaveClick();
            }} 
            className="save-todo-list-button"
            data-tooltip-id="saveTooltip" 
            data-tooltip-content="Save this list to access later.">
            <FaSave />
          </button>
        </div>
        
        {todos.map((todo, index) => (
          <div
            key={todo.id}
            className={index === 0 && animationOn ? 'first-todo' : ''}
            draggable
            onDragStart={(e) => onDragStart(e, todo.id)}
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e, todo.id)}
            
          >
            <Todo
              todo={todo}
              completeTodo={completeTodo}
              removeTodo={removeTodo}
              updateTodo={updateTodo}
              moveToTop={moveToTop} // Pass the new function as a prop
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default TodoList;
