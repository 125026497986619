import React, { useEffect, useState } from 'react'; 
import './App.css';
import TodoList from './components/TodoList';
import MyAccount from './components/MyAccount';
import MyLists from './components/MyLists';
import TermsAndConditions from './components/TermsAndConditions';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'; // Import Link from react-router-dom
import CookieInfoModal from './components/CookieInfoModal'; 
import CookieConsent from "react-cookie-consent";
import PrivacyPolicy from './components/PrivacyPolicy';
import ReactGA from "react-ga4";

import { ThemeProvider } from "./ThemeContext";

ReactGA.initialize('G-5PLZSRED38'); 
function App() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if ("Notification" in window && Notification.permission !== "granted") {
            Notification.requestPermission();
        }
        
    }, []);

    useEffect(() => {
        const hasVisited = localStorage.getItem('hasVisited');
        if (!hasVisited) {
            setIsModalOpen(true);
            localStorage.setItem('hasVisited', 'true');
        }
    }, []);

    const handleClose = () => {
        setIsModalOpen(false);
    };
  const handleCookieAccept = () => {
    ReactGA.event({
        category: "User Interaction",
        action: "Accept Cookies",
        label: "Cookie Consent",
    });
};
    return (
        <ThemeProvider>
        <Router>
            <div className="App">
            <CookieConsent
  location="bottom"
  buttonText="Accept Cookies"
  cookieName="userConsent"
  containerClasses="cookie-container"
  buttonClasses="cookie-button"
  expires={150}
  onAccept={handleCookieAccept}
>
  We use cookies to improve your experience. 
  By continuing to use our app, you accept our{" "}
  <Link 
    to="/privacy" 
    style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
  >
    cookie policy
  </Link>.
</CookieConsent>

        
                <CookieInfoModal isOpen={isModalOpen} onClose={handleClose} />
                <Routes>
                    <Route path="/" element={<TodoList />} />
                    <Route path="/myaccount" element={<MyAccount />} />
                    <Route path="/mylists" element={<MyLists />} />
                    <Route path="/terms" element={<TermsAndConditions />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                </Routes>
            </div>
        </Router>
        </ThemeProvider>
    );
}

export default App;
