import React, { useState } from 'react';
import { IoMenu } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import ToggleSwitch from './ToggleSwitch'; // Import the ToggleSwitch component
import ThemeSelector from '../ThemeSelector'; // Import the ThemeSelector component

function Header({ isAnimationOn, toggleAnimation }) {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="header">
      <ToggleSwitch isOn={isAnimationOn} handleToggle={toggleAnimation} />
      <div className="header-right">
        {/* Theme Selector positioned to the left */}
        <ThemeSelector />
        {/* Menu Icon */}
        <div className="menu-icon" onClick={toggleMenu}>
          <IoMenu />
          {showMenu && (
            <div className="dropdown-menu">
              <Link to="/mylists">My Saved Lists</Link>
              <Link to="/terms">T&C</Link>
              <Link to="/privacy">Privacy Policy</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
