import React from 'react';
import { FaDice } from 'react-icons/fa';
import ReactGA from 'react-ga4';

const Dice = ({ todos, onRandomSelect }) => {
    const incompleteTodos = todos.filter(todo => !todo.isComplete);

    const handleDiceClick = () => {
        if (incompleteTodos.length === 0) {
            alert("No incomplete tasks to select!");
            return;
        }

        const randomIndex = Math.floor(Math.random() * incompleteTodos.length);
        const randomTask = incompleteTodos[randomIndex];

        onRandomSelect(randomTask); // Callback with the selected task
        ReactGA.event({
            category: 'Button',
            action: 'Click Dice',
            label: 'Dice Button',
          });
    };

    return (
        <button onClick={handleDiceClick} className="dice-button"
        data-tooltip-id="diceTooltip" 
          data-tooltip-content="Pick a random task to focus on and move it to the top">
            <FaDice />
        </button>
    );
};

export default Dice;
