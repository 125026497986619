import React, { useState } from 'react';
import './ToggleSwitch.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga4'; // Import Google Analytics

const ToggleSwitch = ({ isOn, handleToggle }) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleToggleWithMessage = () => {
    handleToggle();

    // Track the toggle event
    ReactGA.event({
      category: 'ToggleSwitch',
      action: isOn ? 'Turn Off Focus Mode' : 'Turn On Focus Mode',
      label: 'Focus Mode Toggle',
    });

    if (isMobile && !showInfo) {
      setShowInfo(true);
      setTimeout(() => setShowInfo(false), 3000); // Hide after 3 seconds
    }
  };

  return (
    <div>
      <label 
        className="switch"
        data-tooltip-id="animationToggleTooltip" 
        data-tooltip-content="Focus Mode: Highlight the current task"
      >
        <input 
          type="checkbox" 
          checked={isOn} 
          onChange={handleToggleWithMessage} 
        />
        <span className="slider"></span>
      </label>
      
      {/* Conditionally render tooltip only if not on a mobile device */}
      {!isMobile && (
        <ReactTooltip id="animationToggleTooltip" place="top" effect="solid" />
      )}

      {/* Display a brief message on mobile devices only */}
      {showInfo && isMobile && (
        <div className="info-message">Focus Mode: Highlight the current task</div>
      )}
    </div>
  );
};

export default ToggleSwitch;
