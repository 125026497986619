import React, { useContext, useState } from "react";
import { ThemeContext, themes } from "./ThemeContext";
import { HiOutlineAdjustments } from "react-icons/hi";
import { Tooltip as ReactTooltip } from "react-tooltip"; // Import Tooltip
import ReactGA from "react-ga4"; // Import Google Analytics
import "./components/ThemeSelectorStyles.css"; // Ensure your styles are correctly imported

const ThemeSelector = () => {
  const { theme, changeTheme } = useContext(ThemeContext); // Access theme context
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown toggle

  const handleThemeChange = (themeKey) => {
    ReactGA.event({
      category: "Theme",
      action: "Change Theme",
      label: themeKey,
    });
    changeTheme(themeKey); // Update the theme
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const handleIconClick = () => {
    ReactGA.event({
      category: "Button",
      action: "Open Theme Selector",
      label: "Theme Icon",
    });
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
  };

  return (
    <div className="theme-selector">
      {/* Theme Icon with Tooltip */}
      <div
        className="theme-icon"
        onClick={handleIconClick}
        data-tooltip-id="themeTooltip"
        data-tooltip-content="Choose Theme"
        title="Choose Theme" // Tooltip for accessibility
      >
        <HiOutlineAdjustments />
      </div>

      {/* Tooltip */}
      <ReactTooltip id="themeTooltip" place="top" effect="solid" />

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="theme-dropdown">
          {Object.keys(themes).map((themeKey) => (
            <div
              key={themeKey}
              className={`theme-option ${theme === themeKey ? "active" : ""}`}
              onClick={() => handleThemeChange(themeKey)}
            >
              {themeKey.charAt(0).toUpperCase() + themeKey.slice(1)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ThemeSelector;
