import React from 'react';


const CookieInfoModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-background">
      <div className="modal-content">
      <h2>Warning Before Clearing Cache</h2> <p>Before you clear the cache, please note that all your saved lists, to-do items and reminders are stored locally on your device. <strong>Clearing the cache will permanently delete this data.</strong> To prevent data loss, <em>make sure to save your lists beforehand.</em></p> <h3>How to Save and Manage Your Data</h3> <ol> <li>Click the <strong>Save</strong> button to save your current to-do list.</li> <li>Open the menu in the top-right corner and navigate to the <strong>My Saved List</strong> tab.</li> <li>Select your list and use the <strong>Options</strong> menu to: <ul> <li><strong>Save</strong> the list as a file to your device.</li> <li><strong>Print</strong> the list directly.</li> <li><strong>Share</strong> the list with others.</li> </ul> </li> </ol> <h3> Reminder Reset</h3> <p>Note that after clearing the cache, all reminders will also be removed from local storage. <em>These cannot be saved and must be reset manually if you wish to use them again.</em></p><h3>Tip</h3> <p>For added safety, <em>always save your data securely</em> before performing any action that might clear the cache.</p>
      <button className="modal-button-close" onClick={onClose}>Close</button>
    </div>
    </div>
  );
}

export default CookieInfoModal;
