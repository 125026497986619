// TermsAndConditions.js

import React from 'react';
import BackButton from './BackButton'; // Adjust the import path as necessary
import './TermsAndConditions.css'; // Import CSS file for styling

function TermsAndConditions() {
  return (
    <div className="container">
      <div className='terms-header'>
        <BackButton />
        <h1>Terms and Conditions</h1>
      </div>
      <div className="terms-text">
        <p>
          <strong>Terms and Conditions</strong>
          <ol>
            <li><strong>Description:</strong> Our Task Possible App is a simple and intuitive tool designed to help users organize tasks efficiently. Users can easily add, delete, and mark tasks as completed, with the added convenience of local storage for seamless access to their lists.</li>
            <li><strong>Usage:</strong> By using our Task Possible App, you agree to abide by these terms and conditions. This includes complying with any applicable laws and regulations while using the app.</li>
            <li><strong>Responsibility:</strong> Users are solely responsible for the content they add to the to-do list. We do not assume any liability for the accuracy, completeness, or legality of the tasks entered by the users.</li>
            <li><strong>Data Privacy:</strong> We are committed to protecting the privacy of our users. Any personal information provided while using the app, including data stored locally, will be handled in accordance with our Privacy Policy.</li>
            <li><strong>Local Storage:</strong> The Task Possible App utilizes local storage to save user data locally within the user's web browser. This enables users to access their to-do lists even after closing or refreshing the app, providing a seamless user experience. <strong> <h2> Warning Before Clearing Cache</h2></strong> <p>Before you clear the cache, please note that all your saved lists,to-do items and reminders are stored locally on your device. <strong>Clearing the cache will permanently delete this data.</strong> To prevent data loss, <em>make sure to save your lists beforehand.</em></p> <h3>How to Save and Manage Your Data</h3> <ol> <li>Click the <strong>Save</strong> button to save your current to-do list.</li> <li>Open the menu in the top-right corner and navigate to the <strong>My Saved List</strong> tab.</li> <li>Select your list and use the <strong>Options</strong> menu to: <ul> <li><strong>Save</strong> the list as a file to your device.</li> <li><strong>Print</strong> the list directly.</li> <li><strong>Share</strong> the list with others.</li> </ul> </li> </ol> <li><h3>Reminder Reset</h3> <p>Note that after clearing the cache, all reminders will also be removed from local storage. <em>These cannot be saved and must be reset manually if you wish to use them again.</em></p></li><h3> Tip</h3> <p>For added safety, <em>always save your data securely</em> before performing any action that might clear the cache.</p></li>
            <li>
            <strong>Reminders and Notifications:</strong>
            <p>
              <strong>Functionality:</strong> When you set a reminder within the app, it is designed to notify you at the specified time. However, <strong>notifications will only appear if the app is actively running</strong>. This includes situations where:
            </p>
            <ul>
              <li>The app is open in your web browser.</li>
              <li>The app is installed as a Progressive Web App (PWA) and running on your device.</li>
            </ul>
            <p>
              <strong>Limitations:</strong>
            </p>
            <ul>
              <li>Notifications <strong>will not be delivered</strong> if the app is closed or not running in the background.</li>
              <li>You are responsible for ensuring the app is open or actively running to receive timely notifications.</li>
            </ul>
            <p>
              <strong>Device Permissions:</strong> To enable reminders and notifications, you must:
            </p>
            <ul>
              <li>Grant the app the necessary permissions for notifications on your device or browser.</li>
              <li>Ensure notifications are enabled in your device's settings for the app.</li>
            </ul>
            <p>
              <strong>Disclaimer:</strong> While we strive to provide reliable notifications, the app <strong>does not guarantee notification delivery</strong> in all circumstances. Technical issues, device settings, or connectivity problems may impact the functionality of reminders and notifications.
            </p>
          </li>
            <li><strong>Intellectual Property:</strong> The Task Possible App and all its associated content, including but not limited to code, design, and logos, are the intellectual property of the app owner. Users are not permitted to reproduce, distribute, or modify any part of the app without prior consent.</li>
            <li><strong>Use Restrictions:</strong> Users are prohibited from using the app for any unlawful or unauthorized purpose. This includes but is not limited to engaging in any activity that disrupts the functioning of the app or interferes with other users' access.</li>
            <li><strong>Termination:</strong> We reserve the right to terminate access to the Task Possible App at any time without prior notice if users violate these terms and conditions or engage in any behavior deemed harmful to the app or its users.</li>
            <li><strong>Changes to Terms:</strong> We may update or modify these terms and conditions from time to time without prior notice. Users are encouraged to review the terms periodically to stay informed of any changes.</li>
            <li><strong>Contact Information:</strong> If you have any questions or concerns regarding these terms and conditions, please contact us at napiorka@hotmail.com</li>
          </ol>
          By using our Task Possible App, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you should refrain from using the app.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
