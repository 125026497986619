import React from 'react';
import BackButton from './BackButton'; // Adjust the import path if necessary
import './PrivacyPolicy.css'; // Import the privacy policy styles

function PrivacyPolicy() {
  return (
    <div className="container">
      <div className="privacy-header">
        <BackButton />
        <h1>Privacy Policy</h1>
      </div>
      <div className="privacy-text">
        <p>
          <strong>Privacy Policy</strong>
        </p>
        <p>Last updated: 23 Nov. 24</p>
        <p>
          Task Possible ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our application.
        </p>
        <ol>
          <li>
            <strong>Information We Collect</strong>
            <ol type="I">
              <li><strong>Automatically Collected Information</strong>
                <ul>
                  <li>Usage Data: Interactions with features, buttons (e.g., save, share, print), and navigation.</li>
                  <li>Device Information: Device type, operating system, browser type, and screen size.</li>
                  <li>
                    Cookies and Analytics: Through Google Analytics, we collect anonymous data about:
                    <ul>
                      <li>The number of visits to the app.</li>
                      <li>Pages and features accessed.</li>
                      <li>Event tracking (e.g., button clicks).</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li><strong>Local Data</strong>
                <p>All todo list data and reminders are stored locally on your device. This data is not transmitted to our servers or any third-party servers.</p>
              </li>
              <li><strong>Shared Data</strong>
                <p>If you use the "Share" feature, only the selected data (e.g., a list) is shared externally via your device's sharing functionality. We do not have access to this shared information.</p>
              </li>
            </ol>
          </li>
          <li>
            <strong>Use of Collected Information</strong>
            <ul>
              <li>Improve app performance and usability.</li>
              <li>Understand user interactions with the app features.</li>
              <li>Identify and fix issues or errors.</li>
              <li>Analyse user behaviour to enhance future app updates.</li>
            </ul>
          </li>
          <li>
            <strong>Cookies and Tracking Technologies</strong>
            <p>Our app uses cookies and similar technologies for analytics and functionality:</p>
            <ul>
              <li>Google Analytics: Tracks usage patterns anonymously. No personally identifiable information is collected.</li>
            </ul>
          </li>
          <li>
            <strong>Data Storage and Security</strong>
            <ul>
              <li>Local Data: All your todo lists and reminders remain on your device.</li>
              <li>Analytics Data: Collected by Google Analytics and stored according to their privacy and security policies.</li>
            </ul>
          </li>
          <li>
            <strong>Your Choices</strong>
            <ul>
              <li>Managing Local Data: You can delete or manage your todo lists and reminders from within the app. Once deleted, this data cannot be recovered.</li>
              <li>Cookies and Tracking: Disable cookies and analytics tracking through your device's browser or privacy settings.</li>
            </ul>
          </li>
          <li>
            <strong>Third-Party Services</strong>
            <ul>
              <li>Google Analytics: For tracking user interactions anonymously. No personally identifiable information is shared.</li>
            </ul>
          </li>
          <li>
            <strong>Updates to This Privacy Policy</strong>
            <p>We may update this Privacy Policy periodically. Changes will be reflected in the app and on our website, along with the updated effective date.</p>
          </li>
          <li>
            <strong>Contact Us</strong>
            <p>
              If you have any questions or concerns about this Privacy Policy, please contact us at:
            </p>
            <ul>
              <li>Email: napiorka@hotmail.com</li>
            </ul>
          </li>
        </ol>
        <p>
          By using our app, you agree to this Privacy Policy. If you do not agree, please discontinue using the app.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
