import React, { createContext, useState, useEffect } from "react";

// Define theme options
export const themes = {
  light: {
    "--background-color": "#ffffff",
    "--text-color": "#000000",
    "--button-background": "#e0e0e0",
    "--button-text-color": "#000000",
    "--toggle-background": "#dddddd",
    "--toggle-active": "#525CEB",
    "--todo-row-background-1": "#f0f0f0",
    "--todo-row-background-2": "#e0e0e0",
    "--todo-row-background-3": "#d0d0d0",
    "--todo-row-background-4": "#c0c0c0",
    "--todo-row-background-5": "#b0b0b0",
    "--todo-row-background-6": "#a0a0a0",
    "--dice-button-background": "#e0e0e0",
    "--dice-button-text-color": "#000000",
    "--save-button-background": "#e0e0e0",
    "--save-button-text-color": "#000000",
    "--set-button-background": "#e0e0e0",
    "--set-button-text-color": "#000000",
    "--cancel-button-background": "#e0e0e0",
    "--cancel-button-text-color": "#000000",
  },
  dark: {
    "--background-color": "#282c34",
    "--text-color": "#f8f8f2",
    "--button-background": "#44475a",
    "--button-text-color": "#ffffff",
    "--toggle-background": "#333333",
    "--toggle-active": "#45FFCA",
    "--todo-row-background-1": "rgb(64, 83, 76)",
    "--todo-row-background-2": "rgb(103, 125, 106)",
    "--todo-row-background-3": "rgb(138, 115, 82)",
    "--todo-row-background-4": "rgb(121, 87, 87)",
    "--todo-row-background-5": "rgb(102, 67, 67)",
    "--todo-row-background-6": "rgb(59, 48, 48)",
    "--dice-button-background": "#555555",
    "--dice-button-text-color": "#ffffff",
    "--save-button-background": "#555555",
    "--set-button-background": "#44475a",
  "--set-button-text-color": "#ffffff",
  "--cancel-button-background": "#44475a",
  "--cancel-button-text-color": "#ffffff",

  },
  pastel: {
    "--background-color": "#fdf1f1",
    "--text-color": "#2b2b2b",
    "--button-background": "#fad2e1",
    "--button-text-color": "#2b2b2b",
    "--toggle-background": "#f0e6f6",
    "--toggle-active": "#ff77ff",
    "--todo-row-background-1": "#FFC7ED",
    "--todo-row-background-2": "#ffe5e9",
    "--todo-row-background-3": "#ffb3c1",
    "--todo-row-background-4": "#ffc1d6",
    "--todo-row-background-5": "#ffd1e8",
    "--todo-row-background-6": "#ffebf0",
    "--set-button-background": "#fad2e1",
    "--set-button-text-color": "#2b2b2b",
    "--cancel-button-background": "#fad2e1",
    "--cancel-button-text-color": "#2b2b2b",
  },
  ocean: {
    "--background-color": "#2e8bc0",
    "--text-color": "#ffffff",
    "--button-background": "#145da0",
    "--button-text-color": "#ffffff",
    "--toggle-background": "#1b4f72",
    "--toggle-active": "#8CABFF",
    "--todo-row-background-1": "#3ea8d3",
    "--todo-row-background-2": "#48cae4",
    "--todo-row-background-3": "#90e0ef",
    "--todo-row-background-4": "#4CC9FE",
    "--todo-row-background-5": "#48cae4",
    "--todo-row-background-6": "#00b4d8",
    "--dice-button-background": "#145da0",
    "--dice-button-text-color": "#ffffff",
    "--save-button-background": "#145da0",
    "--save-button-text-color": "#ffffff",
    "--set-button-background": "#145da0",
  "--set-button-text-color": "#ffffff",
  "--cancel-button-background": "#145da0",
  "--cancel-button-text-color": "#ffffff",
  },
};

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");

  // Apply theme variables to the document root
  useEffect(() => {
    const root = document.documentElement;
    const selectedTheme = themes[theme];
    Object.keys(selectedTheme).forEach((key) => {
      root.style.setProperty(key, selectedTheme[key]);
    });
  }, [theme]);

  
  useEffect(() => {
    const savedTheme = localStorage.getItem("app-theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  const changeTheme = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem("app-theme", newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
export default ThemeProvider;